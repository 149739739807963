<template>
<div class="widget-info">
    <vx-card class="mt-8">
        <div class="card-title justify-between">
            <h2>Select a Location</h2>
        </div>
        <div class="review-location">
            <div class="vx-row">
                <div class="vx-col xl:w-1/3 lg:w-1/2 w-full select-location">
                    <v-select v-model="locationSelected" name="Location" class="mt-1" placeholder="Location" :options="locationOptions" :reduce="locationOptions => locationOptions.id" label="name" :clearable="false" @input="locationChange()" />
                    <vs-button type="filled" icon-pack="feather" icon="icon-refresh-cw" class="refresh-btn" color="primary"></vs-button>
                </div>
            </div>
        </div>
    </vx-card>
    <vx-card class="mt-8">
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="card-title justify-between">
                    <h2 class="w-auto">Review Display Widget</h2>
                </div>
            </div>
            <div class="vx-col lg:w-2/5 w-full">
                <div class="create-landing">
                    <div class="landing-section widget-title px-0 border-0">
                        <h3>Widget Setup</h3>
                        <div class="vx-row">
                            <div class="vx-col xl:w-1/3 md:w-1/2 w-full lg-cus-6 widget-option mt-6">
                                <span class="vs-input--label">Background Color</span>
                                <div class="vs-con-input">
                                    <input type="color" id="colorpicker" name="color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="dwBGColor" />
                                    <input type="text" id="hexcolor" class="vs-inputx vs-input--input normal hexcolor" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="dwBGColor" />
                                </div>
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 lg-cus-6 mt-6">
                                <span class="vs-input--label">Slides to Show</span>
                                <input type="number" class="vs-inputx vs-input--input normal" v-model="dwSlideToShow" min="1" @click="reCreateSlick($event)" />
                                <!-- <input
                    type="number"
                    class="vs-inputx vs-input--input normal"
                    v-model="dwSlideToShow"
                    @click="reCreateSlick($event)"
                  />-->
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 lg-cus-6 widget-option mt-6">
                                <span class="vs-input--label">Border Color</span>
                                <div class="vs-con-input">
                                    <input type="color" id="colorpicker" name="color1" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="dwBCColor" />
                                    <input id="hexcolor" type="text" class="vs-inputx vs-input--input normal hexcolor" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="dwBCColor" />
                                </div>
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 lg-cus-6 mt-6">
                                <span class="vs-input--label">Slides to Scroll</span>
                                <input type="number" class="vs-inputx vs-input--input normal" v-model="dwSlideToScroll" />
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 lg-cus-6 widget-option mt-6">
                                <span class="vs-input--label">Text Color</span>
                                <div class="vs-con-input">
                                    <input type="color" id="colorpicker" name="color3" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="dwTCColor" />
                                    <input id="hexcolor" type="text" class="vs-inputx vs-input--input normal hexcolor" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="dwTCColor" />
                                </div>
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 lg-cus-6 mt-6">
                                <span class="vs-input--label">Border Width</span>
                                <input type="number" class="vs-inputx vs-input--input normal" v-model="dwBorderWidth" />
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 lg-cus-6 mt-6">
                                <span class="vs-input--label">Border Radius</span>
                                <input type="number" class="vs-inputx vs-input--input normal" v-model="dwBorderRadius" />
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 lg-cus-6 mt-6 widget-option">
                                <span class="vs-input--label">Text Font</span>

                                <v-select v-model="textFont" name="Text Font" :options="fontFamilyOptions" :reduce="fontFamilyOptions => fontFamilyOptions.value" label="text" :clearable="false" />
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 lg-cus-6 mt-6">
                                <span class="vs-input--label">Auto Play Speed</span>
                                <input type="number" class="vs-inputx vs-input--input normal" v-model="autoplayspeed" />
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 col-cus-12 mt-6">
                                <vs-checkbox v-model="dwVertical" @change="reCreateSlick($event)">Vertical</vs-checkbox>
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 col-cus-12 mt-6">
                                <vs-checkbox v-model="dwAutoPlay" @change="reCreateSlick($event)">Auto Play</vs-checkbox>
                            </div>
                            <div class="vx-col xl:w-1/3 w-full md:w-1/2 col-cus-12 mt-6">
                                <vs-checkbox v-model="dwShowDots" @change="reCreateSlick($event)">Show Dots</vs-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full justify-end float-left">
                        <vs-button color="primary" type="filled">{{ LabelConstant.buttonLabelSave }}</vs-button>
                    </div>
                </div>
            </div>
            <div class="vx-col lg:w-3/5 w-full lg:mt-0 mt-8">
                <div class="setup-preview">
                    <h3>Preview</h3>
                    <div class="carousel">
                        <slick ref="slickOne" :options="slickOptions">
                            <div>
                                <div class="item-details" :style="{padding: '10px', backgroundColor: dwBGColor, fontFamily: dwTextFont, borderStyle: 'solid', borderColor: dwBCColor, borderWidth: dwBorderWidth+'px', borderRadius: dwBorderRadius+'px', color: dwTCColor, width: '100%', display: 'inline-block'}">
                                    <h4 :style="{color: dwTCColor, fontFamily: dwTextFont}">
                                        <img src="@/assets/images/google-map.png" alt="Google Maps" width="100" height="100" />
                                        <span>
                                            User1
                                            <small :style="{color: dwTCColor, fontFamily: dwTextFont}">Reviewed Company 1</small>
                                        </span>
                                    </h4>
                                    <star-rating :increment="0.5" :rating="3.5"></star-rating>
                                    <h5 :style="{color: dwTCColor, fontFamily: dwTextFont}">
                                        Title
                                        <span class="float-right">1-1-2019</span>
                                    </h5>
                                    <p :style="{color: dwTCColor, fontFamily: dwTextFont}">content</p>
                                </div>
                            </div>
                            <div>
                                <div class="item-details" :style="{padding: '10px', backgroundColor: dwBGColor, fontFamily: dwTextFont, borderStyle: 'solid', borderColor: dwBCColor, borderWidth: dwBorderWidth+'px', borderRadius: dwBorderRadius+'px', color: dwTCColor, width: '100%', display: 'inline-block'}">
                                    <h4 :style="{color: dwTCColor, fontFamily: dwTextFont}">
                                        <img src="@/assets/images/google-map.png" alt="Google Maps" width="100" height="100" />
                                        User2
                                        <small :style="{color: dwTCColor, fontFamily: dwTextFont}">Reviewed Company 1</small>
                                    </h4>
                                    <star-rating :increment="0.5" :rating="3.5"></star-rating>
                                    <h5 :style="{color: dwTCColor, fontFamily: dwTextFont}">
                                        Title
                                        <span class="float-right">1-1-2019</span>
                                    </h5>
                                    <p :style="{color: dwTCColor, fontFamily: dwTextFont}">content</p>
                                </div>
                            </div>
                            <div>
                                <div class="item-details" :style="{padding: '10px', backgroundColor: dwBGColor, fontFamily: dwTextFont, borderStyle: 'solid', borderColor: dwBCColor, borderWidth: dwBorderWidth+'px', borderRadius: dwBorderRadius+'px', color: dwTCColor, width: '100%', display: 'inline-block'}">
                                    <h4 :style="{color: dwTCColor, fontFamily: dwTextFont}">
                                        <img src="@/assets/images/google-map.png" alt="Google Maps" width="100" height="100" />
                                        User3
                                        <small :style="{color: dwTCColor, fontFamily: dwTextFont}">Reviewed Company 1</small>
                                    </h4>
                                    <star-rating :increment="0.5" :rating="3.5"></star-rating>
                                    <h5 :style="{color: dwTCColor, fontFamily: dwTextFont}">
                                        Title
                                        <span class="float-right">1-1-2019</span>
                                    </h5>
                                    <p :style="{color: dwTCColor, fontFamily: dwTextFont}">content</p>
                                </div>
                            </div>
                            <div>
                                <div class="item-details" :style="{padding: '10px', backgroundColor: dwBGColor, fontFamily: dwTextFont, borderStyle: 'solid', borderColor: dwBCColor, borderWidth: dwBorderWidth+'px', borderRadius: dwBorderRadius+'px', color: dwTCColor, width: '100%', display: 'inline-block'}">
                                    <h4 :style="{color: dwTCColor, fontFamily: dwTextFont}">
                                        <img src="@/assets/images/google-map.png" alt="Google Maps" width="100" height="100" />
                                        User4
                                        <small :style="{color: dwTCColor, fontFamily: dwTextFont}">Reviewed Company 1</small>
                                    </h4>
                                    <star-rating :increment="0.5" :rating="3.5"></star-rating>
                                    <h5 :style="{color: dwTCColor, fontFamily: dwTextFont}">
                                        Title
                                        <span class="float-right">1-1-2019</span>
                                    </h5>
                                    <p :style="{color: dwTCColor, fontFamily: dwTextFont}">content</p>
                                </div>
                            </div>
                            <div>
                                <div class="item-details" :style="{padding: '10px', backgroundColor: dwBGColor, fontFamily: dwTextFont, borderStyle: 'solid', borderColor: dwBCColor, borderWidth: dwBorderWidth+'px', borderRadius: dwBorderRadius+'px', color: dwTCColor, width: '100%', display: 'inline-block'}">
                                    <h4 :style="{color: dwTCColor, fontFamily: dwTextFont}">
                                        <img src="@/assets/images/google-map.png" alt="Google Maps" width="100" height="100" />
                                        User5
                                        <small :style="{color: dwTCColor, fontFamily: dwTextFont}">Reviewed Company 1</small>
                                    </h4>
                                    <star-rating :increment="0.5" :rating="3.5"></star-rating>
                                    <h5 :style="{color: dwTCColor, fontFamily: dwTextFont}">
                                        Title
                                        <span class="float-right">1-1-2019</span>
                                    </h5>
                                    <p :style="{color: dwTCColor, fontFamily: dwTextFont}">content</p>
                                </div>
                            </div>
                        </slick>
                    </div>
                </div>
            </div>
            <div class="vx-col w-full mt-8">
                <div class="create-landing">
                    <div class="vx-row">
                        <div class="vx-col md:w-1/2 w-full lg:mb-0 mb-4">
                            <div class="landing-section widget-title px-0 border-0">
                                <h3>Widget Code</h3>
                                <vs-textarea class="code-area mt-6 mb-0" label="Code" v-model="displayWidgetJsCodeCustom" />
                            </div>
                        </div>
                        <div class="vx-col md:w-1/2 w-full">
                            <div class="landing-section widget-title px-0 border-0">
                                <h3>Auto Publish Reviews</h3>
                                <div class="flex flex-wrap w-full mt-6 float-left">
                                    <vs-checkbox v-model="autoPlublish">Enable Auto Publishing of Reviews</vs-checkbox>
                                    <div class="w-full mt-6 float-left flex flex-wrap items-center">
                                        <span class="vs-input--label float-left lg:mr-4 lg:mb-0 mb-2 lg:w-auto w-full mr-0">Score equal to or greater than</span>
                                        <v-select v-model="Score" name="Score" class="float-left xl:w-1/5 w-1/2" :options="scoreOptions" :reduce="scoreOptions => scoreOptions.value" label="text" :clearable="false" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full justify-end float-left">
                        <vs-button color="primary" type="filled" @click="saveAutoPublish()">{{ LabelConstant.buttonLabelSaveAutoPublish }}</vs-button>
                    </div>
                </div>
            </div>
        </div>
    </vx-card>
    <vx-card class="mt-8">
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="card-title justify-between">
                    <h2 class="w-auto">Review Collection Widget</h2>
                </div>
            </div>
            <div class="vx-col lg:w-4/12 w-full mb-8">
                <div class="create-landing">
                    <div class="landing-section widget-title px-0 border-0">
                        <h3>Widget Setup</h3>
                        <div class="vx-row">
                            <div class="vx-col w-full widget-option mt-6">
                                <span class="vs-input--label">Background Color</span>
                                <div class="vs-con-input">
                                    <input type="color" id="colorpicker" name="color4" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="wsBGColor" />
                                    <input type="text" class="vs-inputx vs-input--input normal hexcolor" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="wsBGColor" />
                                </div>
                            </div>
                            <div class="vx-col w-full widget-option mt-6">
                                <span class="vs-input--label">Primary Text Color</span>
                                <div class="vs-con-input">
                                    <input type="color" id="colorpicker" name="color5" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="ptTextColor" />
                                    <input type="text" class="vs-inputx vs-input--input normal hexcolor" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="ptTextColor" />
                                </div>
                            </div>
                            <div class="vx-col w-full widget-option mt-6">
                                <span class="vs-input--label">Text Color</span>
                                <div class="vs-con-input">
                                    <input type="color" id="colorpicker" name="color6" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="seTextColor" />
                                    <input type="text" class="vs-inputx vs-input--input normal hexcolor" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="seTextColor" />
                                </div>
                            </div>
                            <div class="vx-col w-full mt-6 widget-option">
                                <span class="vs-input--label">Text Font</span>
                                <v-select v-model="dwTextFont" :clearable="false" :options="fontFamilyOptions" :reduce="fontFamilyOptions => fontFamilyOptions.value" label="text" />
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full justify-end float-left">
                        <vs-button color="primary" type="filled">{{ LabelConstant.buttonLabelSave }}</vs-button>
                    </div>
                </div>
            </div>
            <div class="vx-col lg:w-4/12 w-full mb-8">
                <div class="create-landing" :style="{backgroundColor: wsBGColor}">
                    <div class="landing-section widget-title px-0 border-0 widget-form">
                        <h2>Preview</h2>
                        <h3 :style="{color: ptTextColor, fontFamily: textFont}">Write a Review</h3>
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <h4 class="mt-6 mb-4 review-form-title" :style="{color: seTextColor, fontFamily: textFont}">How did we do?*</h4>
                                <star-rating :show-rating="false" active-color="#ECB940"></star-rating>
                            </div>
                        </div>
                        <template>
                            <div class="vx-row mb-2 mt-2">
                                <div class="vx-col w-full">
                                    <label for class="vs-input--label" :style="{color: seTextColor, fontFamily: textFont}">Name</label>
                                    <vs-input class="w-full" v-model="name" />
                                </div>
                            </div>
                            <div class="vx-row mb-2">
                                <div class="vx-col w-full">
                                    <label for class="vs-input--label" :style="{color: seTextColor, fontFamily: textFont}">Email</label>
                                    <vs-input class="w-full" type="email" v-model="eMail" />
                                </div>
                            </div>
                            <div class="vx-row mb-2">
                                <div class="vx-col w-full">
                                    <label for class="vs-input--label" :style="{color: seTextColor, fontFamily: textFont}">Review Title</label>
                                    <vs-input class="w-full" v-model="reviewTitle" />
                                </div>
                            </div>
                            <div class="vx-row mt-6">
                                <div class="vx-col w-full">
                                    <label for class="vs-input--label" :style="{color: seTextColor, fontFamily: textFont}">Comments</label>
                                    <vs-textarea class="mb-0" v-model="textarea" />
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="flex w-full justify-end float-left">
                        <vs-button color="primary" type="filled">{{ LabelConstant.buttonLabelSubmit }}</vs-button>
                    </div>
                </div>
            </div>
            <div class="vx-col lg:w-4/12 w-full mb-8">
                <div class="create-landing">
                    <div class="landing-section widget-title px-0 border-0 widget-form pb-0">
                        <h3>Widget Code</h3>
                        <template>
                            <div class="vx-row">
                                <div class="vx-col w-full">
                                    <vs-textarea class="code-area mt-6 mb-0" label="Code" v-model="displayCollectionJsCodeCustom" />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </vx-card>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import Slick from "vue-slick";
import StarRating from "vue-star-rating";
import "../../../node_modules/slick-carousel/slick/slick.css";

export default {
    components: {
        VxCard,
        vSelect,
        Slick,
        StarRating
    },
    data() {
        return {
            dwVertical: false,
            dwAutoPlay: false,
            wLocationId: null,
            uniqueLocationId: null,
            dwBorderWidth: 1,
            dwSlideToScroll: 1,
            autoplayspeed: 3000,
            vertical: false,
            autoplay: false,
            dwShowDots: false,
            dwSlideToShow: 1,
            name: null,
            eMail: null,
            reviewTitle: null,
            autoPlublish: false,
            textarea: null,
            dwBGColor: "#ffffff",
            dwBCColor: "#000000",
            dwTCColor: "#000000",
            wsBGColor: "#ffffff",
            textFont: "Arial",
            dwBorderRadius: 1,
            ptTextColor: "#000000",
            seTextColor: "#000000",
            selectImage: false,
            landingtype: "",
            locationSelected: null,
            //Domain dropdown options
            locationOptions: [],
            dwTextFont: "Arial",

            //Font family dropdown for Text Font
            fontFamilyOptions: [{
                    text: "Arial",
                    value: "Arial"
                },
                {
                    text: "Arial Black",
                    value: "Arial Black"
                },
                {
                    text: "Arial Narrow",
                    value: "Arial Narrow"
                },
                {
                    text: "Calibri",
                    value: "Calibri"
                },
                {
                    text: "Calisto MT",
                    value: "Calisto MT"
                },
                {
                    text: "Candara",
                    value: "Candara"
                },
                {
                    text: "Century Gothic",
                    value: "Century Gothic"
                },
                {
                    text: "Consolas",
                    value: "Consolas"
                },
                {
                    text: "Copperplate",
                    value: "Copperplate"
                },
                {
                    text: "Courier New",
                    value: "Courier New"
                },
                {
                    text: "Cursive",
                    value: "Cursive"
                },
                {
                    text: "Didot",
                    value: "Didot"
                },
                {
                    text: "Fantasy",
                    value: "Fantasy"
                },
                {
                    text: "Franklin Gothic Medium",
                    value: "Franklin Gothic Medium"
                },
                {
                    text: "Georgia",
                    value: "Georgia"
                },
                {
                    text: "Helvetica",
                    value: "Helvetica"
                },
                {
                    text: "Impact",
                    value: "Impact"
                },
                {
                    text: "Lucida Console",
                    value: "Lucida Console"
                },
                {
                    text: "Monospace",
                    value: "Monospace"
                },
                {
                    text: "Sans-serif",
                    value: "Sans-serif"
                },
                {
                    text: "Segoe UI",
                    value: "Segoe UI"
                },
                {
                    text: "Serif",
                    value: "Serif"
                },
                {
                    text: "Tahoma",
                    value: "Tahoma"
                },
                {
                    text: "Times New Roman",
                    value: "Times New Roman"
                },
                {
                    text: "Trebuchet MS",
                    value: "Trebuchet MS"
                },
                {
                    text: "Verdana",
                    value: "Verdana"
                }
            ],
            Score: null,
            scoreOptions: [{
                    text: "0",
                    value: 0
                },
                {
                    text: "1",
                    value: 1
                },
                {
                    text: "2",
                    value: 2
                },
                {
                    text: "3",
                    value: 3
                },
                {
                    text: "4",
                    value: 4
                },
                {
                    text: "5",
                    value: 5
                }
            ],
            slickOptions: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
                dots: false,
                draggable: true,
                edgeFriction: 0.15,

                responsive: [{
                        breakpoint: 1365,
                        settings: {
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            }
        };
    },
    mounted() {
        this.getLocationList();
        setTimeout(() => {
            document
                .querySelector("#colorpicker")
                .addEventListener("change", function () {
                    document.getElementById("hexcolor").value = this.value;
                });
            document
                .querySelector("#hexcolor")
                .addEventListener("change", function () {
                    document.getElementById("colorpicker").value = this.value;
                });
        }, 1000);
    },
    watch: {},
    computed: {
        displayWidgetJsCodeCustom: function () {
            let scriptCode =
                `<script async src ="https://widget.reviewlandingpage.com/ReviewWidget/GetReviews?id=` +
                this.uniqueLocationId +
                `&bc=` +
                encodeURIComponent(this.dwBGColor) +
                `&brdc=` +
                encodeURIComponent(this.dwBCColor) +
                `&tc=` +
                encodeURIComponent(this.dwTCColor) +
                `&bw=` +
                this.dwBorderWidth +
                `&br=` +
                this.dwBorderRadius +
                `&f=` +
                this.dwTextFont +
                `&scroll=` +
                this.dwSlideToScroll +
                `&show=` +
                this.dwSlideToShow +
                `&v=` +
                this.dwVertical +
                `&a=` +
                this.dwAutoPlay +
                `&s=` +
                this.autoplayspeed +
                `&d=` +
                this.dwShowDots +
                `" type="application/javascript" /> \n<div class="vv-review-widget"></div>`;

            return scriptCode;
        },
        displayCollectionJsCodeCustom: function () {
            let wsbg = this.wsBGColor;
            wsbg = wsbg.substring(1);
            let ptc = this.wsBGColor;
            ptc = ptc.substring(1);
            let sc = this.seTextColor;
            sc = sc.substring(1);

            let iframeCode =
                '<iframe scrolling="no" height="600" src="https://widget.reviewlandingpage.com/ReviewWidget/Generate_Review_Widget?lid=' +
                this.uniqueLocationId +
                "=&bc=" +
                wsbg +
                "&pc=" +
                ptc +
                "&sc=" +
                sc +
                "&ff=" +
                this.textFont +
                ' frameborder="0"></iframe>';

            return iframeCode;
        }
    },
    methods: {
        //get location list
        async getLocationList() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ReviewsGetStarted/GetLocationList")
                .then(response => {
                    let data = response.data;
                    if (data.length <= 0) {
                        this.$router.push("/reputation-management/getting-started/uid/" + this.$route.params.id + "?setLocation=true");
                    }
                    let location = [];
                    data.forEach((element, index) => {
                        if (index == 0) {
                            this.locationSelected = element.LocationId;
                        }
                        location.push({
                            id: element.LocationId,
                            name: element.LocationName ?
                                element.LocationName : element.BusinessName
                        });
                    });
                    this.locationOptions = location;
                    this.getLocationId();
                    this.getWidgetConfig();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // when we change fromatting 
        reCreateSlick(e) {
            if (e.type == "click" || e.type == "change") {
                this.slickOptions = {
                    slidesToShow: parseInt(this.dwSlideToShow),
                    slidesToScroll: parseInt(this.dwSlideToScroll),
                    infinite: false,
                    arrows: false,
                    dots: this.dwShowDots,
                    draggable: true,
                    edgeFriction: 0.3,
                    vertical: this.dwVertical,
                    autoplay: this.dwAutoPlay,
                    autoplaySpeed: parseInt(this.autoplayspeed)
                };
                this.$nextTick(() => {
                    this.$refs.slickOne.reSlick();
                });
            }
        },
        // get location
        async getLocationId() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ReviewWidget/GetLocationID?id=" + this.locationSelected)
                .then(response => {
                    let data = response.data;
                    this.uniqueLocationId = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get widget config data
        async getWidgetConfig() {
            this.$vs.loading();
            await this.axios
                .get(
                    "/ws/ReviewWidget/GetWidgetAutoConfigInfo?id=" + this.locationSelected
                )
                .then(response => {
                    let data = response.data;
                    this.autoPlublish = data.AutoPublishEnabled;
                    this.Score = data.MinScore;
                    this.wLocationId = data.LocationId;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // update or save auto publish saving 
        async saveAutoPublish() {
            this.$vs.loading();
            let input = {
                LocationId: this.locationSelected,
                AutoPublishEnabled: this.autoPlublish,
                MinScore: this.Score
            };
            await this.axios
                .post("/ws/ReviewWidget/UpdateWidgetAutoConfigInfo", input)
                .then(() => {
                    // let data = response.data;
                    // console.log(data);
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get widget data 
        async locationChange() {
            this.getLocationId();
            this.getWidgetConfig();
            setTimeout(() => {
                this.displayWidgetJsCodeCustom();
                this.displayCollectionJsCodeCustom();
            }, 100);
        }
    }
};
</script>
